import React from 'react';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import InstallationLayout from '../../components/Installation/InstallationLayout';
import {
  DOCUMENTATION_ROUTE,
  DOWNLOAD_LINUX_ROUTE,
  INSTALL_SETUP_DOCUMENTATION_ROUTE,
} from '../../const/routes.const';
import { docsDefaultLanguage, docsSupportedLanguages } from '../../const/language.const';
import LinkWrapper from '../../components/common/LinkWrapper';
import { getUrlWithLocale } from '../../utils/url.utils';

const InstallationLinPage = (): React.ReactElement => {
  const { language } = useI18next();

  return (
    <InstallationLayout
      note={
        <Trans i18nKey="Please note that Octo Browser for Linux is currently in beta-testing. If you encounter any difficulties while downloading or installing Octo Browser, please consult the FAQ.">
          Please note that Octo Browser for Linux is currently in beta-testing. If you encounter any
          difficulties while downloading or installing Octo Browser, please consult the <LinkWrapper
            external
            isNative
            url={`${getUrlWithLocale(
              language,
              DOCUMENTATION_ROUTE,
              docsDefaultLanguage,
              docsSupportedLanguages
            ).slice(0, -1)}${INSTALL_SETUP_DOCUMENTATION_ROUTE}`}
          >
            FAQ
          </LinkWrapper>
          .
        </Trans>
      }
      steps={[
        <Trans i18nKey="Download the archive containing Octo Browser Beta for Linux" key={1}>
          <LinkWrapper external isNative url={DOWNLOAD_LINUX_ROUTE}>
            Download the archive
          </LinkWrapper> containing Octo Browser <sup>Beta</sup> for Linux
        </Trans>,
        <Trans key={2}>Extract the archive</Trans>,
        <Trans key={3}>Run OctoBrowser.AppImage</Trans>,
      ]}
      title={<Trans>Download Octo Browser for Linux</Trans>}
    />
  );
};

export default InstallationLinPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["installationPage", "installationLinPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
